/* eslint-disable no-console -- intended only for debugging locally */

import { ApolloLink, Observable } from '@apollo/client';

export const debugLink = new ApolloLink((operation, forward) => {
  const { operationName, variables } = operation;

  console.time(`[GraphQL Request]: ${operationName}`);
  console.log(`[GraphQL Request]: ${operationName}`, variables);

  return new Observable((observer) => {
    const sub = forward(operation).subscribe({
      next: (result) => {
        console.timeEnd(`[GraphQL Request]: ${operationName}`);
        console.dir({ '[GraphQL Response]': operationName, result }, { depth: null });
        observer.next(result);
      },
      error: (err) => {
        console.error(`[GraphQL Error]: ${operationName}`, err);
        observer.error(err);
      },
      complete: () => observer.complete(),
    });

    return () => {
      sub.unsubscribe();
    };
  });
});
