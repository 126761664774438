/**
 * The "Identity" is the definition of "something" interacting
 * with the landing project. It's domain is limited to this project
 * and should not be influenced by customer implementation details.
 *
 * A customer is just one form of an identity, so in this case the "Identity" is
 * reshaped from a customer on the server.
 *
 * The "Identity" abstraction stops internal implementation details from pouring into
 * our client applications, allowing us to easily change where the identity originates.
 * For instance, the identity could arrive from Auth0 or Cognito, we don't care.
 */

import { gql } from '@apollo/client';

export enum IdentityClientExpectedErrorCode {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

/**
 * @warn `PENDING` is used also when the user is registered.
 */
export type IdentityProfileType = 'GUEST' | 'PENDING';

export interface IdentityProfile {
  readonly identityId: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly mobileNumber?: string;
  readonly email?: string;
  readonly status: IdentityProfileType;
}

export interface IdentityCheck {
  readonly isAuthenticatedGuest?: boolean;
  readonly isAuthenticatedCustomer?: boolean;
  readonly isAuthenticated: boolean;
  readonly identityProfile?: IdentityProfile;
}

export const QUERY_GET_IDENTITY_PROFILE = gql`
  query getIdentityProfile {
    getIdentityProfile {
      identityId
      status
      firstName
      lastName
      mobileNumber
      email
    }
  }
`;

export const QUERY_CHECK_IDENTITY = gql`
  query checkIdentity {
    checkIdentity {
      isAuthenticatedGuest
      isAuthenticatedCustomer
      isAuthenticated
      identityProfile {
        identityId
        status
        firstName
        lastName
        mobileNumber
        email
      }
    }
  }
`;
